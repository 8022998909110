import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("mask", {
      id: "i861982048__a",
      width: "24",
      height: "24",
      x: "0",
      y: "0",
      maskUnits: "userSpaceOnUse",
      style: {"mask-type":"alpha"}
    }, [
      _createElementVNode("path", {
        fill: "#D9D9D9",
        d: "M0 0h24v24H0z"
      })
    ], -1),
    _createElementVNode("g", { mask: "url(#i861982048__a)" }, [
      _createElementVNode("path", {
        fill: "currentColor",
        d: "M3 18v-2h18v2H3Zm0-5v-2h18v2H3Zm0-5V6h18v2H3Z"
      })
    ], -1)
  ])))
}
export default { render: render }