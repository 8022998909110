<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  Popover,
  PopoverButton,
  PopoverPanel,
  provideUseId,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  breakpointsTailwind,
  useBreakpoints,
} from '@vueuse/core'
import queryNavigation from '~~/graphql/queries/Navigation/Navigation.query.graphql'
import queryTermsEntries from '~~/graphql/queries/Terms/TermsEntries.query.graphql'
import { useDossiersStore } from '~~/stores/dossiers'
import { useSiteStore } from '~~/stores/site'
import { useUserStore } from '~~/stores/user'
import IconSearch from 'assets/svgs/search.svg'
import IconClose from '~/assets/svgs/close.svg?component'
import IconLogo from '~/assets/svgs/crossarea_logo.svg?component'
import IconHamburger from '~/assets/svgs/hamburger.svg?component'

const props = defineProps({
  isDark: {
    type: Boolean,
    default: false,
  },
  classNames: {
    type: String,
    default: '',
  },
  isFrontpage: {
    type: Boolean,
    default: false,
  },
})

provideUseId(() => useId())

const route = useRoute()
const router = useRouter()
// const { status, data: loginData, signOut, signIn } = useAuth()
const { isAuthenticated, user } = useSanctumAuth()
// const { isAuthenticated, user } = useAuth()
// const status = ref('')
const userStore = useUserStore()
const dossiersStore = useDossiersStore()
const siteStore = useSiteStore()
const color = useColorMode()
const colors = ['light', 'dark']
const { staticText } = useText()

const isSticky = false

const { readingList } = storeToRefs(userStore)

const breakpoints = useBreakpoints(breakpointsTailwind)
const md = breakpoints.between('md', 'lg')
const lg = breakpoints.between('lg', 'xl')
const mobile = breakpoints.smallerOrEqual('lg')

const { data, pending, error } = await useAsyncQuery(queryNavigation, {
  navHandle: 'main',
})
const filteredMain = computed(() => data.value?.nav.tree ?? [])

const {
  data: metaNav,
} = await useAsyncQuery(queryNavigation, {
  navHandle: 'secondary_main',
})
const filteredSub = computed(() => metaNav.value?.nav.tree ?? [])

// get research areas
const {
  data: termsData,
} = await useAsyncQuery(queryTermsEntries, {
  name: ['ordering_knowledge'],
})
const filteredTerms = computed(() => termsData.value?.terms.data ?? [])

const nav = ref<HTMLElement | null>(null)
const sentinel = ref<HTMLElement | null>(null)

// Search
const isSearchOpen = ref(false)

function openSearch() {
  isSearchOpen.value = true
}
function closeSearch() {
  isSearchOpen.value = false
}

// Mobile Menu
const isMobileMenuOpen = ref(false)
function openMobileMenu() {
  isMobileMenuOpen.value = true
}
function closeMobileMenu() {
  isMobileMenuOpen.value = false
}
function switchMobileMenu() {
  isMobileMenuOpen.value = !isMobileMenuOpen.value
}

router.beforeEach((to, from, next) => {
  if (isMobileMenuOpen.value)
    closeMobileMenu()

  // if (isSearchOpen.value) {
  //   closeSearch()
  // }
  next()
})
</script>

<template>
  <div
    ref="sentinel"
    class="max-lg:top-2 max-md:top-2 absolute h-[50px] pb-[160px]"
  />
  <header
    ref="nav"
    class="max-lg:max-w-[calc(100dvw-2rem)] max-lg:rounded-[1.25rem] max-md:max-w-[calc(100dvw-1rem)] top-2 z-[100] mx-auto w-full text-white transition-colors duration-200 ease-in-out md:top-3 lg:top-0"
    :class="[
      props.classNames,
      {
        'bg-dark/70 shadow-header backdrop-blur': isSticky,
        'bg-dark': !isSticky,
        'bg-transparent': props.isFrontpage && !isSticky,
        'max-md:mx-2': !props.isFrontpage,
      },
    ]"
  >
    <div class="container mx-auto">
      
      <div
        :class="[
          {
            'py-4 lg:p-0': isSticky,
            'py-4 lg:pb-10 lg:pt-[30px]': !isSticky,
          },
        ]"
      >
        <div
          v-motion-fade
          class="navbar-end duration-250 hidden flex-1 transition-all ease-out lg:flex lg:justify-end"
          :class="[
            {
              'mb-0 h-0 overflow-clip opacity-0': isSticky,
              'mb-2 h-auto opacity-100': !isSticky,
            },
          ]"
        >
          <ul
            class="flex items-center justify-end gap-[35px]"
          >
            
            <li
              v-if="siteStore.featureFlags.toggle_reading_list"
              class="group flex items-center justify-center gap-x-2"
            >
              <LazyUserReadingList />
            </li>
            <li v-if="siteStore.featureFlags.toggle_user_login && !isAuthenticated">
              <NuxtLink
                class="text-h6 font-[400] text-white hover:text-primary-200"
                to="/auth/register"
              >
                Register
              </NuxtLink>
            </li>
            <li v-if="siteStore.featureFlags.toggle_user_login">
              <NuxtLink
                v-if="!isAuthenticated"
                class="flex text-h6 font-[400] text-white hover:text-primary-200"
                to="/auth/login"
              >
                Login
              </NuxtLink>
              <NuxtLink
                v-else
                class="text-h6 font-[400] text-white hover:text-primary-200"
                to="/user"
              >
                Hallo {{ user.user.first_name }} {{ user.user.name }}
              </NuxtLink>
            </li>
            <li>
              <HeaderLink
                to="/contribute"
                class="rounded-lg bg-primary-200 px-2 py-1 text-h6 font-[400] font-medium text-dark hover:text-white"
              >
                {{ staticText.app.contribute }}
              </HeaderLink>
            </li>
            <template v-for="(tree, index) in filteredSub" :key="index">
              

              <li>
                <HeaderLink
                  v-if="!tree.children.length"
                  :to="tree.page.slug ? `/${tree.page.slug}` : tree.page.url"
                  class="text-h6 font-[400] text-white hover:text-primary-200"
                >
                  {{ tree.page.title }}
                </HeaderLink>
              </li>
            </template>
          </ul>
        </div>
        <div
          class="navbar flex grow flex-row items-center justify-between lg:flex lg:justify-end"
        >
          <NuxtLink
            class="navbar-start w-auto justify-start"
            to="/"
            aria-label="Back to the startpage"
          >
            <IconLogo
              class="h-8 w-auto transition-transform duration-300 ease-in-out"
              :class="[
                {
                  'lg:mb-2 lg:mt-3 lg:scale-75': isSticky,
                  'lg:h-[100px]': props.isFrontpage,
                  'lg:h-[72px]': !props.isFrontpage,
                },
              ]"
            />
          </NuxtLink>

          <div v-motion-fade class="navbar-end w-auto flex-1">
            <ul
              v-if="!pending"
              class="flex items-center justify-end gap-4 lg:gap-10"
            >
              <li class="">
                <NuxtLink
                  to="/search"
                  class="group flex items-center justify-center gap-x-2 rounded-full p-2 pr-4 text-h5 transition-colors duration-300 ease-in-out last:mr-0 hover:bg-primary-200 hover:text-white"
                  :class="{
                    'bg-primary-400 text-white': route.path.includes('search'),
                    'bg-white text-dark': !route.path.includes('search'),
                  }"
                >
                  <IconSearch
                    class="size-6 text-dark transition-all duration-300 ease-in-out group-hover:text-white lg:size-6"
                    :class="[
                      {
                        'text-white': route.path.includes('search'),
                      },
                    ]"
                  />
                  <span class="text-lg font-bold not-italic leading-6">{{
                    staticText.app.search
                  }}</span>
                </NuxtLink>
              </li>
              <li v-if="mobile">
                <button
                  type="button"
                  class="group relative z-[100] flex items-center justify-center gap-x-[11px] rounded-full p-2 text-h5 transition-colors duration-300 ease-in-out last:mr-0 hover:bg-primary-200 hover:text-white"
                  :class="{
                    'bg-primary-400 text-white': isMobileMenuOpen,
                    'bg-white text-dark': !isMobileMenuOpen,
                  }"
                  @click="switchMobileMenu"
                >
                  <IconHamburger
                    class="z-[100] text-dark transition-all duration-300 ease-in-out group-hover:text-white"
                    :class="[
                      {
                        'size-6 lg:size-6': isSticky,
                        'size-6 lg:size-10': !isSticky,
                        'bg-primary-400 text-white group-hover:bg-primary-200':
                          isMobileMenuOpen,
                      },
                    ]"
                  />
                  <span class="sr-only">{{ staticText.app.menu }}</span>
                </button>
                <TransitionRoot appear :show="isMobileMenuOpen" as="template">
                  <Dialog as="div" @close="closeMobileMenu">
                    <div
                      class="container absolute top-0 mx-auto h-full overflow-y-auto rounded-[1.25rem]"
                    >
                      <TransitionChild
                        as="template"
                        enter="duration-300 ease-in-out"
                        enter-from="opacity-0"
                        enter-to="opacity-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 "
                        leave-to="opacity-0"
                      >
                        <DialogPanel
                          focus
                          class="absolute inset-x-0 top-0 z-[101] m-2 mx-auto mb-4 h-auto w-[calc(100vw-1rem)] rounded-[1.25rem] bg-dark/80 pb-6 shadow-dropdown backdrop-blur-2xl focus:outline-none md:mt-3 md:w-[calc(100vw-2rem)]"
                        >
                          <div
                            class="container mx-auto flex items-center justify-end gap-4 py-4"
                          >
                            <NuxtLink
                              class="w-auto grow justify-start text-primary-200"
                              to="/"
                              aria-label="Back to the startpage"
                            >
                              <IconLogo
                                class="h-8 w-auto transition-transform duration-300 ease-in-out lg:h-[72px]"
                                :class="[
                                  {
                                    'lg:mb-2 lg:mt-3 lg:scale-75': isSticky,
                                  },
                                ]"
                              />
                            </NuxtLink>
                            <NuxtLink
                              to="/search"
                              class="group flex items-center justify-center gap-x-[11px] rounded-full p-2 text-h5 transition-colors duration-300 ease-in-out last:mr-0 hover:bg-primary-200 hover:text-white"
                              :class="{
                                'bg-primary-400': route.path.includes('search'),
                              }"
                            >
                              <IconSearch
                                class="text-dark transition-all duration-300 ease-in-out group-hover:text-white"
                                :class="[
                                  {
                                    'size-6 lg:size-6': isSticky,
                                    'size-6 lg:size-10': !isSticky,
                                  },
                                ]"
                              />
                              <span class="sr-only">{{
                                staticText.app.search
                              }}</span>
                            </NuxtLink>
                            <button
                              type="button"
                              class="group relative z-[100] flex items-center justify-center gap-x-[11px] rounded-full p-2 text-h5 transition-colors duration-300 ease-in-out last:mr-0 hover:bg-primary-200 hover:text-white"
                              :class="{
                                'bg-primary-400 text-white': isMobileMenuOpen,
                                'bg-white text-dark': !isMobileMenuOpen,
                              }"
                              @click="switchMobileMenu"
                            >
                              <IconClose
                                class="z-[100] text-dark transition-all duration-300 ease-in-out group-hover:text-white"
                                :class="[
                                  {
                                    'size-6 lg:size-6': isSticky,
                                    'size-6 lg:size-10': !isSticky,
                                    'bg-primary-400 text-white group-hover:bg-primary-200':
                                      isMobileMenuOpen,
                                  },
                                ]"
                              />
                              <span class="sr-only">{{
                                staticText.app.menu
                              }}</span>
                            </button>
                          </div>
                        </DialogPanel>
                      </TransitionChild>
                    </div>
                  </Dialog>
                </TransitionRoot>
              </li>
              <template v-for="(tree, index) in filteredMain" :key="index">
                <li v-if="!tree.children.length && !mobile">
                  <HeaderLink
                    :to="tree.page.slug ? `/${tree.page.slug}` : tree.page.url"
                    class="text-h5 text-white transition-colors duration-300 ease-in-out hover:text-primary-200"
                  >
                    {{ tree.page.title }}
                  </HeaderLink>
                </li>

                <li
                  v-if="tree.children && tree.children.length > 0 && !mobile"
                  tabindex="0"
                >
                  <PopoverRoot>
                    <Popover as="div" class="relative inline-block text-left">
                      <div>
                        <PopoverButton
                          class="text-h5 text-white transition-colors duration-300 ease-in-out hover:text-primary-200"
                          :class="{
                            'relative font-semibold text-white after:absolute after:inset-x-0 after:-bottom-2 after:h-[4px] after:translate-x-0 after:translate-y-0 after:rotate-0 after:skew-x-0 after:skew-y-0 after:scale-100 after:rounded-[4px] after:bg-white after:opacity-100 after:transition-all after:duration-300 after:ease-in-out hover:after:bg-primary-200':
                              route.path.includes(tree.page.slug),
                          }"
                        >
                          {{ tree.page.title }}
                        </PopoverButton>
                      </div>

                      <transition
                        enter-active-class="transition duration-200 ease-out"
                        enter-from-class="translate-y-1 opacity-0"
                        enter-to-class="translate-y-0 opacity-100"
                        leave-active-class="transition duration-150 ease-in"
                        leave-from-class="translate-y-0 opacity-100"
                        leave-to-class="translate-y-1 opacity-0"
                      >
                        <PopoverPanel
                          class="absolute right-0 z-10 mt-5 w-screen max-w-xs origin-top-right divide-y divide-gray-100 rounded-[8px] bg-white pb-2 pt-1 shadow-dropdown ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          <div class="grid grid-cols-1">
                            <template
                              v-for="navchild in tree.children"
                              :key="navchild.id"
                            >
                              <HeaderSubLink
                                class="w-full px-8 text-[18px] font-[500] leading-[40px] text-dark lg:hover:bg-primary-200"
                                :to="navchild.page.url"
                              >
                                {{ navchild.page.title }}
                              </HeaderSubLink>
                            </template>
                          </div>
                        </PopoverPanel>
                      </transition>
                    </Popover>
                  </PopoverRoot>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
